import { render, staticRenderFns } from "./prod_list.vue?vue&type=template&id=39724bf4&"
import script from "./prod_list.vue?vue&type=script&lang=js&"
export * from "./prod_list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports