<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_zadmin_head />
        <inc_zadmin_top />
        <inc_zadmin_title />
        <!--================ //inc head top =================-->

        <form id="regiForm" onsubmit="return false;">

            <!--전달값-->
            <input type="hidden" name="no" v-model="view_1.NO" />
            <!--//전달값-->

            <div class="pu_board">
                <div class="pu_row">
                    <div class="pu_contents">

                        <!--================ inc left =================-->
                        <inc_zadmin_left />
                        <!--================ //inc left =================-->

                        <div class="pu_body">
                            <div class="pu_row">
                                <div class="pu_contents">

                                    <div class="pu_row">
                                        <div class="pu_contents">

                                            <table class="pu_table_view">
                                                <colgroup>
                                                    <col width="15%">
                                                    <col width="35%">
                                                    <col width="15%">
                                                    <col width="35%">
                                                </colgroup>
                                                <tbody>

                                                    <tr>
                                                        <th>구분</th>
                                                        <td colspan="3">
                                                            <label class="pu_input_text_label">
                                                                <select name="gubun" v-model="view_1.GUBUN"
                                                                        class="pu_input_select dev_input_must">
                                                                    <option value="A1">아기반지</option>
                                                                    <option value="A2">아기팔찌</option>
                                                                    <option value="A3">금수저</option>
                                                                    <option value="B1">골드바</option>
                                                                    <option value="C1">실버바</option>
                                                                    <option value="D1">순금기념품</option>
                                                                    <option value="E1">리사이클 제품 (중고)</option>
                                                                </select>
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>제품명</th>
                                                        <td colspan="3">
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="title" v-model="view_1.TITLE"
                                                                       class="pu_input_text dev_input_must"
                                                                       placeholder="" />
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>돈(무게)</th>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="weight" v-model="view_1.WEIGHT"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" />
                                                            </label>
                                                        </td>
                                                        <th>세공비(원)</th>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="cost_carve" v-model="view_1.COST_CARVE"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" />
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            사진<br /><br />
                                                            개수 : {{ cnt_lim_pic }}개<br />
                                                            확장자 : jpg, jpeg, png

                                                            <a class="btn btn-block btn-success" @click="addFiles" href="javascript:;">파일추가</a>

                                                        </th>
                                                        <td colspan="3">
                                                            <ul id="file_item_list" class="pu_file_item_ul"
                                                                v-html="$ConHtml(view_1.HTML_FILE_ITEM_UPLOAD)"></ul>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>
                                                            썸네일
                                                            <br /><br />- 대표사진 추가 후
                                                            <br />파일을 선택하면
                                                            <br />썸네일 생성 가능합니다
                                                        </th>
                                                        <td>

                                                            <!--이미지 에디터-->
                                                            <tui-image-editor id='dev_img_editor'
                                                                              ref='tuiImageEditor'
                                                                              :include-ui='false'></tui-image-editor>
                                                            <!--//이미지 에디터-->

                                                        </td>
                                                        <th>
                                                            기존 썸네일
                                                        </th>
                                                        <td>
                                                            <div class="pu_img_editor_thum" v-bind:style="{ backgroundImage: 'url(' + url_img_item_thum + ')' }"></div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>설명</th>
                                                        <td colspan="3">
                                                            <textarea name="contents" class="pu_input_textarea dev_input_must" v-html="view_1.CONTENTS">설명</textarea>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>공개여부</th>
                                                        <td colspan="3">
                                                            <label class="pu_input_radio_label">
                                                                <input type="radio" name="show_yn"
                                                                       class="pu_input_radio dev_input_must" value="Y" v-model="view_1.SHOW_YN" checked="checked" /> 공개
                                                            </label>
                                                            <label class="pu_input_radio_label">
                                                                <input type="radio" name="show_yn"
                                                                       class="pu_input_radio dev_input_must" value="N" v-model="view_1.SHOW_YN" /> 비공개
                                                            </label>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div class="pu_row">
                                        <div class="pu_contents">

                                            <div class="pu_col_lg_1">
                                                <a class="pu_btn pu_btn_list" href="javascript:;" v-on:click="$PageMove('./prod_list')">
                                                    <i class="fas fa-list-ul"></i> 목록
                                                </a>
                                            </div>

                                            <div class="pu_col_lg_1">
                                                <a class="dev_btn_delete pu_btn pu_btn_del" v-if="view_1.NO > 0" href="javascript:;">
                                                    <i class="far fa-trash-alt"></i> 삭제
                                                </a>
                                            </div>

                                            <div class="pu_col_lg_9"></div>

                                            <div class="pu_col_lg_1">
                                                <a class="dev_btn_submit pu_btn pu_btn_regi" href="javascript:;">
                                                    <i class="fas fa-edit"></i> 저장
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </form>

        <!--================ inc foot =================-->
        <inc_zadmin_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    import { ImageEditor } from "@toast-ui/vue-image-editor";

    // export
    export default {
        
        components: {
            "tui-image-editor": ImageEditor
        }

        , computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , cnt_lim_pic: 5        // 이미지 count

                , url_img_item_thum: "" // 썸네일 url

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData () {

                var qs = this.$GetQueryMap();
                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/zadmin/prod/view", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰
                        this.url_img_item_thum = data.VIEW_1.URL_IMG_ITEM_THUM;

                        if (!this.view_1.GUBUN) {  // 구분
                            this.view_1.GUBUN = "A1";
                        }

                        if (!this.view_1.SHOW_YN) {  // 보임여부
                            this.view_1.SHOW_YN = "Y";
                        }

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }

                    // ck editor 세팅
                    this.setCkEditor();
                }
            }

            // 저장
            , saveData () {

                // 제품명
                var title = $("[name='title']");
                if (!title.val().trim()) {
                    alert("제목을 입력해주세요");
                    title.focus();
                    return false;
                }

                // 돈
                var weight = $("[name='weight']");
                if (!weight.val().trim()) {
                    alert("돈(무게)를 입력해주세요");
                    weight.focus();
                    return false;
                }

                // 세공비
                var cost_carve = $("[name='cost_carve']");
                if (!cost_carve.val().trim()) {
                    alert("세공비(원) 입력해주세요");
                    cost_carve.focus();
                    return false;
                }

                //사진
                var pic_no = $("[name='dev_file_item_no']");
                if (pic_no.length < 1) {
                    alert("사진을 첨부해주세요.");
                    this.addFiles();
                    return false;
                }

                // 설명
                CKEDITOR.instances.contents.updateElement();
                var contents = $("[name='contents']");
                if (!contents.val().trim()) {
                    alert("설명을 입력해주세요");
                    contents.focus();
                    return false;
                }

                // 공개여부                        
                var show_yn = $("[name='show_yn']:checked");
                if (show_yn.length < 1) {
                    alert("공개여부를 선택해주세요");
                    $($("[name='show_yn']")[0]).focus();
                    return false;
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data["gubun_page"] = this.gubun_page;

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/prod/save", data, this.saveEventReturn);
                }
            }

            // 저장 데이터
            , saveEventReturn (data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 삭제
            , deleteData: function () {

                if (confirm(INFO_CONF_DELETE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    // 데이터
                    var data = {};
                    data["gubun_page"] = this.gubun_page;
                    data["no"] = this.view_1.NO;

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/prod/del", data, this.deleteDataReturn);
                }
            }

            // 삭제 데이터
            , deleteDataReturn: function (data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // ck editor 세팅
            , setCkEditor: function () {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "prod_gold_baby", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }

                }, 100);
            }

            // 파일 추가
            , addFiles: function () {
                AddFiles("prod_gold_baby", this.cnt_lim_pic, ".jpg,.jpeg,.png");
            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>