import { render, staticRenderFns } from "./inc_zadmin_top.vue?vue&type=template&id=491d8ce6&"
import script from "./inc_zadmin_top.vue?vue&type=script&lang=js&"
export * from "./inc_zadmin_top.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports