<template>

    <!--================ Foot Menu Area =================-->

    <div class="pu_foot">
        <div class="pu_row">
            <div class="pu_contents">
                <div class="pu_short_cut">
                    <a href="javascript:;" v-on:click="$PageMove('./greeting' + '')">
                        회사소개
                    </a>
                    &emsp;|&emsp;
                    <a href="javascript:;" v-on:click="$PageMove('./buy_prod' + '')">
                        매입품목
                    </a>
                    &emsp;|&emsp;
                    <a href="javascript:;" v-on:click="$PageMove('./prod_gold_baby' + '')">
                        판매제품
                    </a>
                    &emsp;|&emsp;
                    <a href="javascript:;" v-on:click="$PageMove('./board_qna' + '')">
                        고객센터
                    </a>
                </div>
                <div class="pu_info">
                    상호 : 성복금거래소
                    <br />경기도 용인시 수지구 성복2로 76번길 26-6, 107호 (성복동, 신봉 V샤르망 1차 1층) / 대표 : 이승환
                    <br />사업자등록번호 : 142-07-87125
                    <br />TEL : 031-263-8471 / FAX : 031-263-8474 / E-mail : hwan8471@naver.com
                </div>
                <div class="pu_copyright">
                    © Copyright <a href="http://hyper-nomad.com">HyperNomad</a>. All Rights Reserved
                </div>
            </div>
        </div>
    </div>

    <!--================ //Foot Menu Area =================-->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {

                THIS_NAME: "INC FOOT"

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

            }

        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
